body {
  font-family: 'Open Sans Condensed', sans-serif;
  background: white;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto
}

#topo {
  width: 100%;
  background-color: white;
  padding-top: 30px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  height: 25vh;
}

.sidenav-trigger {
  color: black;
}

nav {
  background-color: white;
  box-shadow: none;
}

.nav-bar {
  width: 100%;
  /* position: absolute; */
}

.nav-container {
  margin: 0;
}

.nav-menu {
  display: none;
}

.nav-logo {
  margin: 0;
  margin-left: 15%;
}

.nav-logo .logo {
  width: 15%;
}

nav.nav-bar ul {
  list-style: none;
}

.nav-list {
  margin: 0 auto;
  width: 60%;
}

.nav-list li {
  padding: 1x;
  float: left;
  /* width: 12.5%; */
  /* border-radius: 0px 0px 20px 20px; */
  /* -moz-border-radius: 0px 0px 20px 20px; */
  /* -webkit-border-radius: 0px 0px 20px 20px; */
  border: 0px solid #000000;
  -webkit-clip-path: circle(80.0% at 50% 5%);
  -moz-clip-path: circle(80.0% at 50% 5%);
  clip-path: circle(80.0% at 50% 5%);
}

.solaris-customized {
  border: 1px solid #eee;
  box-shadow: none;
}

.solaris-customized li.active .collapsible-body {
  border: none;
}

.collapsible-header {
  border-bottom: none;
  color: #F39415;
  font-weight: bold;
  font-size: 1.3rem;
}

.center-text {
  margin: 0 auto;
}

.btn {
  background-color: #F39415;
}

.btn:hover,
.btn:active {
  background-color: #F39415;
  -webkit-filter: brightness(1.1);
  filter: brightness(1.1);
}

.calculators {
  margin: 0;
}

/* label color */
.input-field label {
  color: #F39415;
}

/* label focus color */
.input-field input[type=number]:focus+label {
  color: #F39415;
}

/* label underline focus color */
.input-field input[type=number]:focus {
  border-bottom: 1px solid #F39415 !important;
  box-shadow: 0 1px 0 0 #F39415 !important;
}

.btn:focus {
  background-color: #F39415;
}

.solaris__button {
  width: auto;
}

/* label underline focus color */
.input-field input[type=number]:active {
  border-bottom: 1px solid #F39415;
  box-shadow: 0 1px 0 0 #F39415;
}


/* valid color */
.input-field input[type=number].valid {
  border-bottom: 1px solid #F39415;
  box-shadow: 0 1px 0 0 #F39415;
}

/* invalid color */
.input-field input[type=number].invalid {
  border-bottom: 1px solid #F39415;
  box-shadow: 0 1px 0 0 #F39415;
}

/* icon prefix focus color */
.input-field .prefix.active {
  color: #F39415;
}

.nav-list li a {
  border: 1px solid #fff;
  display: block;
  color: #f9f9f9;
  padding: 30px 30px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  /* border-radius: 0px 0px 20px 20px;
  -moz-border-radius: 0px 0px 20px 20px;
  -webkit-border-radius: 0px 0px 20px 20px; */
}

.nav-list li a:hover {
  filter: brightness(1.1);
  -webkit-filter: brightness(1.1);
  padding-top: 70px;
  padding-bottom: 70px;
}

#bg-item {
  background: #F39415;
}

.footer {
  clear: both;
  background-color: #818282;
  color: white;
  height: 200px;
  /* position: sticky; */
  position: relative;
  bottom: 0;
  /* left: 0;
  right: 0; */
  margin-bottom: 0;
}

.footer__item {
  display: flex;
  width: 100%;
  justify-content: center;
}

.footer__h2 {
  margin-top: 2rem;
  font-size: 1.3rem;
  line-height: 1rem;
}

.solaris__services-block {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.solaris__text-header {
  margin-bottom: 3rem;
}

.solaris__text-header-bold {
  font-weight: bold;
  margin-top: 2.5rem;
}

.solaris__text-centered {
  margin-top: 0;
}

a.footer__h2,
a.footer__h2:active,
a.footer__h2:focus,
a.footer__h2:hover,
a.footer__h2:visited {
  color: white;
}

.footer__content-link {
  margin-top: 2rem;
}

.solaris__hometext,
.solaris__attention-modal {
  font-size: 1.3rem;
}

.footer__text {
  font-size: 1.1rem;
  line-height: 1rem;
}

.footer__end {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100px;
  z-index: 3;
  background-color: #F39415;
  -webkit-clip-path: ellipse(50% 45% at 50% 100%);
  -moz-clip-path: ellipse(50% 45% at 50% 100%);
  clip-path: ellipse(50% 45% at 50% 100%);
}

/* clip-path: ellipse(50% 10% at 50% 100%); */

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .75);
  z-index: 500;

  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-solaris {
  /* margin: auto; */
  width: 40%;
  background-color: white;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}

.modal-solaris__header {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 0.6rem;
  margin: 0;

  display: flex;
}

.modal-solaris__logo {
  margin: 0 auto;
  width: 35%;
  height: 100%;
}

.modal-solaris__close {
  cursor: pointer;
  font-size: 2rem;
  margin-left: auto;
  line-height: 1px;
  height: auto;
  width: auto;
  color: #F39415;

  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.modal-solaris.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}

.modal-solaris__content {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.modal-solaris__content-contact {
  padding: 2rem;
}

.modal-solaris__content__text {
  color: #1F9B38;
  text-align: center;
  font-size: 1.5rem;
}

.modal-solaris__actions {
  border-top: 1px solid #ccc;
  background: white;
  padding: 1rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.btn {
  margin: 0.5rem;
}

.modal-solaris__footnote {
  color: #aaa;
  justify-self: flex-start;
  margin: 0;
  margin-top: 0.5rem;
  flex-basis: 100%;
}

.carousel-estrategia {
  height: 700px;
}

.carousel .carousel-item {
  width: 75% !important;
}

.quote-image {
  margin-top: 1rem;
}

.footer__mailto {
  color: white;
  font-size: 1.1rem;
  line-height: 1rem;
  text-decoration: none;
}

.solaris__bottom {
  margin-top: 5%;
}

.solaris__text,
.solaris__text-li {
  text-align: justify;
}

.solaris__text-li {
  list-style-type: disc;
}

.solaris__text>a {
  color: #F39415;
}

.solaris__attention,
.solaris__attention-modal {
  font-weight: bold;
  color: #F39415;
}

.solaris__image-common {
  margin: 2.5rem 0 0 0;
}

.solaris__header-green {
  margin: 1rem 0 .5rem 0;
  color: #1F9B38;
}

.solaris__header-main {
  margin: 2rem 0 1.15rem 0;
}

.solaris__card-img {
  padding: 15px 50px 0 50px;
}

.solaris__card-name {
  margin-top: 2rem;
  color: #F39415;
  font-weight: bold;
  font-size: 1.5rem;
}

.solaris__header-about,
.solaris__header-info {
  border-bottom: 1px solid #eee;
  text-transform: uppercase;
}

.solaris__header-info {
  margin-top: 3rem;
}

.solaris__about-text {
  font-size: 1.4rem;
  padding: 0 5rem;
  color: #F39415;
  text-align: justify;
}

.solaris__video-subtitle {
  color: #F39415;
  font-size: .8rem;
  text-align: end;
  margin-right: 10rem;
  margin-top: 0;
}

.alice-carousel {
  text-align: center;
}

.videoWrapper {
  --aspect-ratio: 3 / 4;
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.solaris__img-default {
  width: 80%;
}

.solaris__img-vertical {
  width: 50%;
}

/*
//  Media Queries
//  -------------------------- */
@media(max-width: 994px) {
  .nav-logo {
    display: flex;
    justify-content: center;
  }

  .nav-logo .logo {
    height: 30%;
    width: 60%;
  }

  .sidenav-trigger {
    margin-top: -15% !important;
  }

  .modal-solaris {
    width: 60%;
  }
}

@media(max-width: 700px) {
  .nav-logo {
    display: flex;
    justify-content: center;
  }

  .nav-logo .logo {
    height: 30%;
    width: 60%;
  }

  .sidenav-trigger {
    margin-top: -15% !important;
  }

  .modal-solaris {
    width: 90%;
  }

  .solaris__about-text {
    padding: 0 1rem;
  }
}

@media(max-width: 600px) {
  .footer__item {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }

  .nav-logo {
    display: flex;
    justify-content: center;
  }

  .nav-logo .logo {
    height: 30%;
    width: 60%;
  }

  .footer {
    height: 420px;
  }

  .sidenav-trigger {
    margin-top: -20% !important;
  }

  .modal-solaris {
    width: 90%;
  }

  .solaris__about-text {
    padding: 0 1rem;
  }
}

@media(max-width: 360px) {
  .nav-logo .logo {
    height: 30%;
    width: 60%;
  }

  .sidenav-trigger {
    margin-top: -25% !important;
  }

  .modal-solaris {
    width: 90%;
  }

  .solaris__about-text {
    padding: 0 1rem;
  }
}

/*  /////////////////////////   */
#solaris__velocimeter {
  display: inline-block;
  position: relative;
  flex-basis: 20%;
}

#solaris__velocimeter .solaris__velocimeter-bg {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 20px solid #F39415;
  border-right: 20px solid white;
  border-bottom: 20px solid white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}

#solaris__velocimeter .solaris__velocimeter-needle {
  width: 5px;
  height: 41px;
  display: inline-block;
  left: 37px;
  top: 0px;
  background: #000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  position: absolute;
  -webkit-animation: move 5s;
  animation: move 5s;
  transform: rotate(90deg);
  transform-origin: bottom;
}

#solaris__velocimeter .solaris__velocimeter-needle-reject {
  width: 5px;
  height: 41px;
  display: inline-block;
  left: 37px;
  top: 0px;
  background: #000;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
  position: absolute;
  -webkit-animation: notwork 5s;
  animation: notwork 5s;
  transform: rotate(-90deg);
  transform-origin: bottom;
}

@keyframes notwork {
  0% {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  50% {
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
  }

  100% {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

@-webkit-keyframes notwork {
  0% {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  50% {
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
  }

  100% {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}


@keyframes move {
  0% {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  100% {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
